import { environment } from '@awork/environments/environment'
import { Environment } from '@awork/environments/types'

export enum ErrorType {
  App = 'app',
  Angular = 'angular',
  Vendor = 'vendor',
  External = 'external',
  Unknown = 'unknown'
}

/**
 * Determines if is a valid error
 * @param error
 * @param {Environment} env
 */
function isValidError(error: Error, env: Environment): boolean {
  return !(!(error instanceof Error) || !error.message || !error.stack || env === 'local')
}

/**
 * Classifies the error
 * @param {Error} error
 * @param {Environment} env
 */
export function classifyError(error: Error, env?: Environment): ErrorType {
  env = env || environment

  if (!isValidError(error, env)) {
    return ErrorType.Unknown
  }

  const stackLines = error.stack.split('\n')

  if (stackLines.length <= 2) {
    return ErrorType.Unknown
  }

  let cdnUrl = 'app/'

  switch (env) {
    case 'develop':
      cdnUrl += 'dev'
      break
    case 'staging':
      cdnUrl += 'staging'
      break
    case 'production':
      cdnUrl += 'prod'
  }

  const vendorScripts = ['vendor', 'polyfills']

  // Not from awork
  if (!stackLines[1].includes(cdnUrl)) {
    return ErrorType.External
  }

  // From Angular
  if (error.message.includes('NG')) {
    return ErrorType.Angular
  }

  // From a dependency
  if (
    vendorScripts.some(script => stackLines[1].includes(`${cdnUrl}/${script}`)) ||
    stackLines[1].includes(`node_modules`) || // True when source maps are available (staging, develop)
    stackLines.some(line => line.includes(`polyfills`))
  ) {
    return ErrorType.Vendor
  }

  // From the app
  if (stackLines[1].includes(`${cdnUrl}`)) {
    return ErrorType.App
  }

  // Unidentifiable error
  return ErrorType.Unknown
}
