<aw-modal
  #modal
  [size]="size.s"
  [showCloseButton]="true"
>
  <figure class="image aw-2-txt--center">🫠</figure>

  <div class="aw-2-txt--center">
    <h1 class="aw-2-mb--l">{{ translations.title }}</h1>

    <div>
      <p class="subtitle">{{ translations.subtitle }}</p>
      <p>{{ translations.subtitle2 }}</p>
    </div>
  </div>

  <div modal-footer class="footer">
    <aw-button
      *ngIf="error"
      [size]="size.m"
      [color]="color.Blue"
      [type]="'secondary'"
      [text]="translations.newTicket"
      (onClick)="createTicket()"
    />
    <div class="footer-right">
      <aw-button
        [size]="size.m"
        [color]="color.Blue"
        [type]="'tertiary'"
        [text]="translations.close"
        (onClick)="hide()"
      />
      <aw-button
        [size]="size.m"
        [color]="color.Blue"
        [type]="'primary'"
        [text]="translations.reload"
        (onClick)="reload()"
      />
    </div>
  </div>
</aw-modal>
