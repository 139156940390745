import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { Component, effect, Renderer2, ViewContainerRef } from '@angular/core'
import { NavigationCancel, Router, ActivatedRoute, NavigationEnd, RouterOutlet } from '@angular/router'
import { AccountService } from '@awork/_shared/services/account-service/account.service'
import { NavigationService } from './_shared/services/navigation-service/navigation.service'
import { environment } from '@awork/environments/environment'
import { maskUrl } from './_shared/functions/url-helpers'
import { PlanService } from '@awork/_shared/services/plan-service/plan.service'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { getContext, initialize, registerOnThemeChangeHandler } from '@microsoft/teams-js'
import { Meta } from '@angular/platform-browser'
import { AppQuery } from '@awork/core/state/app.query'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { forkJoin, filter, take } from 'rxjs'
import { ElectronService } from '@awork/_shared/services/electron-service/electron.service'
import { DynamicRefService } from './_shared/services/dynamic-ref-service/dynamic-ref.service'
import { ToastService } from './_shared/services/toast-service/toast.service'
import { ModalService } from './_shared/services/modal-service/modal.service'
import { FeatureFlagIntegrationService } from '@awork/_shared/services/feature-flag-integration-service/feature-flag-integration.service'

@Component({
  selector: 'aw-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  public lastRoute: string
  isMSTeams: boolean = false

  // the viewRef is necessary to have the root ref available in other components
  // this is for creating notifications, modals, etc.
  constructor(
    private viewRef: ViewContainerRef,
    private dynamicRefService: DynamicRefService,
    public router: Router,
    public route: ActivatedRoute,
    public navigationService: NavigationService,
    private modalService: ModalService,
    private toastService: ToastService,
    private trackingService: TrackingService,
    private planService: PlanService,
    private accountService: AccountService,
    private settingsQuery: SettingsQuery,
    private browserService: BrowserService,
    private renderer: Renderer2,
    private meta: Meta,
    private appQuery: AppQuery,
    private logService: LogService,
    private electronService: ElectronService,
    private featureFlagIntegrationService: FeatureFlagIntegrationService
  ) {
    document.body.classList.add(this.browserService.isMobilePhone ? 'aw-mobile' : 'aw-web')

    this.dynamicRefService.appViewRef = viewRef
    this.logService.initSentry()
    this.logService.initDatadogRum()

    this.appQuery.selectIsInMSTeams().subscribe(() => {
      this.isMSTeams = true
      this.initTeams()
    })

    // some global router event subscriptions
    this.router.events.subscribe(event => {
      // Store the attempted URL by subscribing to the NavigationCancel event
      if (event instanceof NavigationCancel) {
        // Saving URL from canceled route.
        this.accountService.setRedirectUrl(event.url)
      }

      // Store the last route in the navigation service
      if (event instanceof NavigationEnd) {
        this.removeLoader()

        // Track page views
        // track event is for mixpanel (page is being ignored)
        this.trackingService.trackEvent(TrackingEvent.pageViewed, {
          url: maskUrl(event.url),
          is_paid: this.planService.isPaidPlan(),
          integrations: {
            All: true,
            'Google Analytics': false,
            'Facebook Pixel': false
          },
          screen_width: window.innerWidth,
          embedded_in: this.getEmbeddedIn()
        })

        // Track the page explicitly for other segment destinations
        this.trackingService.trackPage(maskUrl(event.url))

        // Hide all modals on navigating
        this.modalService.hideAllModals()

        // hide the purple background if framework module is shown
        // show it again if it's being removed.
        // delay needed because the id is set by hostbinding
        setTimeout(() => {
          if (document?.getElementById('aw-lyt')) {
            if (!document.body.className.includes('rdy')) {
              document.body.classList.add('rdy')
            }
          } else {
            if (document.body?.className?.includes('rdy')) {
              document.body.classList.remove('rdy')
            }
          }
        })
      }
    })

    this.awConsole()
    this.setDarkMode()

    this.initDesktopApp()
    this.checkExtendTrialStatus()
  }

  /**
   * Gets the embedded in key string for tracking
   * @returns {string}
   */
  private getEmbeddedIn(): string {
    let embeddedIn = 'default'

    if (this.isMSTeams) {
      return (embeddedIn = 'ms-teams')
    }

    if (this.electronService.isElectron) {
      switch (this.browserService.getOS()) {
        case 'Mac':
          embeddedIn = 'electron-osx'
          break
        case 'Windows':
          embeddedIn = 'electron-win'
          break
      }
    }

    return embeddedIn
  }

  /**
   * Initializes MS Teams SDK
   */
  private initTeams(): void {
    initialize()

    // register handler if the ms team theme is changed
    registerOnThemeChangeHandler(theme => {
      this.setDarkMode(theme)
    })

    // override current mode if it is in ms teams
    getContext(context => {
      this.setDarkMode(context.theme)
    })
  }

  /**
   * You know what it is ;)
   */
  private awConsole(): void {
    if (environment !== 'local') {
      /* eslint-disable */
      console.log(
        `
                          _
                         | |
  __ ___      _____  _ __| | __
 / _' \\ \\ /\\ / / _ \\| '__| |/ /
| (_| |\\ V  V / (_) | |  |   <
 \\__,_| \\_/\\_/ \\___/|_|  |_|\\_\\


Nothing to see here! Stay productive ;)
`
      )
      /* eslint-enable */
    }
  }

  /**
   * Sets the app dark mode
   */
  private setDarkMode(msTeamsTheme?: string): void {
    effect(
      () => {
        const frameworkSettings = this.settingsQuery.queryViewSetting('framework')

        let darkMode = false

        // If MS teams theme is dark, use dark mode
        if (msTeamsTheme === 'dark') {
          darkMode = true
          // if not yet saved, set to auto
        } else if (
          (!frameworkSettings() || frameworkSettings().darkMode === undefined) &&
          this.browserService.isBrowserDarkMode()
        ) {
          darkMode = true

          // else use the setting
        } else if (frameworkSettings() && frameworkSettings().darkMode) {
          darkMode =
            frameworkSettings().darkMode === 'on' ||
            (frameworkSettings().darkMode === 'auto' && this.browserService.isBrowserDarkMode())
        }

        if (darkMode) {
          this.renderer.addClass(document.body, 'aw-2-dark')
          this.meta.updateTag({ name: 'theme-color', content: '#3E3F42' })
          this.browserService.isDarkMode$.set(true)
        } else {
          this.renderer.removeClass(document.body, 'aw-2-dark')
          this.meta.updateTag({ name: 'theme-color', content: '#FFFFFF' })
          this.browserService.isDarkMode$.set(false)
        }
      },
      { allowSignalWrites: true }
    )
  }

  /**
   * Removes the App's loader from the DOM
   * @private
   */
  private removeLoader(): void {
    forkJoin({
      checkingVersion: this.appQuery.selectCheckingVersion().pipe(
        filter(checking => !checking),
        take(1)
      ),
      params: this.route.queryParams.pipe(take(1))
    }).subscribe(data => {
      if (data.params.loading !== 'true' || this.appQuery.getIsLoggedIn()) {
        const loader = document.getElementById('loader')

        if (loader) {
          document.body.removeChild(loader)
        }
      }
    })
  }

  /**
   * Initializes the desktop app if the user has the desktop app installed
   * If there is no setting stored yet, but running in the desktop app, we
   * set the setting to true
   */
  private initDesktopApp(): void {
    if (!this.browserService.isMobilePhone) {
      this.electronService.initialize(this.appQuery.getOpenLinksInDesktopApp())
    }
  }

  /**
   * Checks if the trial got extended to show a toast message
   */
  private checkExtendTrialStatus(): void {
    this.planService.extendTrialStatus.subscribe(status => {
      if (status === 'success') {
        this.toastService.show(q.translations.FrameworkComponent.extendedTrialSuccessful)
      } else {
        this.toastService.show(q.translations.FrameworkComponent.extendedTrialError, { type: 'error' })
      }
    })
  }
}
