import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http'
import { environment, apiEndpoint, version } from '@awork/environments/environment'
import { Observable } from 'rxjs'
import { SignalService } from '@awork/_shared/services/signal-service/signal.service'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { AccountState } from '@awork/_shared/models/account.model'
import { guidRegexString } from '@awork/_shared/static-data/regular-expressions'
import getHostname from '@awork/_shared/functions/get-hostname'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private accountQuery: AccountQuery
  private signalService: SignalService

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.accountQuery = this.injector.get(AccountQuery)
    this.signalService = this.injector.get(SignalService)

    const apiEntry = '/api/v1'
    const requestApiUrl = getHostname(req.url) + apiEntry
    const apiUrl = getHostname(apiEndpoint) + apiEntry

    if (req.url.includes(apiEndpoint) || requestApiUrl === apiUrl) {
      // Default request options
      const options = {
        withCredentials: true
      }

      let headers = req.headers ? req.headers : new HttpHeaders()

      // If the request is 'cookie' or 'token' (login), set the required header
      if (req.url.endsWith('cookie') || req.url.endsWith('token')) {
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded')
      }

      if (!this.isExcluded(req) && this.signalService.connectionId !== null) {
        headers = headers.set('aw-websocket-connection-id', this.signalService.connectionId)
      }

      // If in dev mode, some headers need to be added.
      if (environment === 'local') {
        const account: AccountState = this.accountQuery.getAccount()

        // In all other cases (excluding 'refreshtoken' and 'cookie' endpoint),
        // the access token needs to be added because we do not have a cookie.
        if (account.accessToken !== null && !req.url.endsWith('refreshtoken') && !req.url.endsWith('cookie')) {
          const accessToken = account.accessToken
          headers = headers.set('Authorization', `Bearer ${accessToken}`)
        }

        // To get the access token in the response of the login endpoint
        headers = headers.set('aw-mobile', 'true')
      }

      // Set default headers
      headers = headers.set('accept', 'application/json')
      headers = headers.set('aw-version', version)

      options['headers'] = headers

      // Clone the request to add the new options
      const newReq = req.clone(options)

      // Pass on the cloned request instead of the original request.
      return next.handle(newReq)
    } else {
      return next.handle(req)
    }
  }

  /**
   * Determines if the call needs to be excluded from sending the websocket connection id.
   * When the websocket connection id is not sent, then we can receive websockets of own user
   */
  isExcluded(req: HttpRequest<any>) {
    const excludedCalls = [
      {
        url: 'addtaskbundle',
        method: 'POST',
        checkForEndsWith: true
      },
      {
        url: 'projects',
        method: 'POST',
        checkForEndsWith: true
      },
      {
        url: 'addProjects',
        method: 'POST',
        checkForEndsWith: true
      },
      {
        url: `tasklists/${guidRegexString}/copy`,
        method: 'POST',
        checkForEndsWith: true
      },
      {
        url: 'batch/copy',
        method: 'POST',
        checkForEndsWith: true
      },
      {
        url: `tasks/${guidRegexString}/copy`,
        method: 'POST',
        checkForEndsWith: true
      }
    ]
    return excludedCalls.some(excludedCall => {
      const excludedURL = excludedCall.checkForEndsWith ? excludedCall.url + '$' : excludedCall.url
      const regex = new RegExp(excludedURL)

      return req.method === excludedCall.method && regex.test(req.url)
    })
  }
}
