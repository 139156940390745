<article class="wrapper" [ngClass]="view">
  <div class="preview-wrapper aw-2-pos--relative aw-2-b--radius-m">

    <!-- Loader -->
    <aw-dots-loader *ngIf="isLoading" class="aw-2-pos--center-all"/>

    <!-- Image thumbnail -->
    <img
      *ngIf="view === 'image'"
      class="clickable"
      [hidden]="isLoading"
      [src]="previewUrl"
      (load)="imageLoaded()"
      (error)="imageLoadingError()"
    />

    <!-- Icon wrapper -->
    <div
      *ngIf="['icon', 'externalFile'].includes(view)"
      class="icon-wrapper"
     >
      <aw-file-icon
        [extension]="file.fileExtension"
        [externalProvider]="file.externalProvider"
        [size]="sizes.l"
      />
    </div>

    <!-- Actions -->
    <div class="actions-wrapper aw-2-flx aw-2-pos--absolute">
      <!-- Show External File -->
      <a *ngIf="view === 'externalFile'" target="_blank" [href]="file.externalFileUrl">
        <aw-fab-button
          icon="visibility"
          [size]="sizes.s"
          [awTooltip]="q.translations.common.view"
          [actionTooltip]="true"
          [showDelay]="200"
        />
      </a>
      <!-- Open Carousel -->
      <aw-fab-button
        icon="search"
        [size]="sizes.s"
        [awTooltip]="q.translations.common.preview"
        [actionTooltip]="true"
        [showDelay]="200"
        (click)="onView()"
      />
    </div>
  </div>

  <div
    *ngIf="['icon', 'externalFile'].includes(view)"
    class="aw-2--flx"
  >
    <p
      class="filename aw-2-txt--truncate aw-2-txt--night aw-2-txt--bold aw-2-flx--flex-1"
    >
      {{ file.name }}
    </p>
    <p
      class="filesize aw-2-txt--steel aw-2-txt--bold"
    >
      {{ file.size | fileSize }}
    </p>
  </div>

</article>
