import { Routes } from '@angular/router'
import { AuthGuard } from './auth/auth-guard.service'
import { FrameworkGuard } from './framework/framework-guard.service'

export default [
  {
    path: '',
    loadChildren: () => import('./auth/auth.routes')
  },
  {
    path: '',
    loadChildren: () => import('./framework/framework.routes'),
    canActivate: [AuthGuard, FrameworkGuard]
  },
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full'
  }
] as Routes
