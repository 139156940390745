import localeDe from '@angular/common/locales/de'
import { AppComponent } from './app/app.component'
import { bootstrapApplication } from '@angular/platform-browser'
import { registerLocaleData } from '@angular/common'
import { setInjector } from './app/_shared/decorators/re-init'
import { createCustomElement } from '@angular/elements'
import { InlineFilePreviewComponent } from './app/_shared/components/files/inline-file-preview/inline-file-preview.component'
import appConfig from './app/app.config'

registerLocaleData(localeDe, 'de')

bootstrapApplication(AppComponent, appConfig)
  .then(applicationRef => {
    // Set the Injector used by the ReInit decorator
    setInjector(applicationRef.injector)

    // Convert `InlineFilePreviewComponent` to a custom element.
    const FilePreviewElement = createCustomElement(InlineFilePreviewComponent, { injector: applicationRef.injector })
    // Register the custom element with the browser.
    customElements.define('aw-file-preview-element', FilePreviewElement)
  })
  .catch(error => console.error(error))
