import { effect, inject, Injectable } from '@angular/core'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { FeatureFlagStore, LaunchDarklyFeatureFlags } from '@awork/_shared/state/feature-flag.store'
import { initialize, LDClient, LDContext, LDOptions } from 'launchdarkly-js-client-sdk'
import { environment } from '@awork/environments/environment'

interface FlagUpdates {
  string: { current: boolean; previous: boolean }
}

const CLIENT_ID = environment === 'production' ? '664b275445554710265392f1' : '6645dad0469b8f1226e08d7e'

/**
 * Service to integrate feature flags with LaunchDarkly
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagIntegrationService {
  private workspaceQuery = inject(WorkspaceQuery)
  private featureFlagStore = inject(FeatureFlagStore)

  private client: LDClient
  private workspaceId: string

  constructor() {
    effect(() => this.initWorkspaceFeatureFlags())
  }

  /**
   * Initializes the workspace feature flags
   * @withSignal {Signal<Workspace>} workspaceQuery.queryCurrentWorkspace
   */
  private initWorkspaceFeatureFlags(): void {
    const workspace = this.workspaceQuery.queryCurrentWorkspace()

    if (!workspace() || workspace().id === this.workspaceId) {
      return
    }

    this.client?.close()

    this.workspaceId = workspace().id

    const context: LDContext = {
      kind: 'workspace',
      key: this.workspaceId,
      name: workspace().name
    }

    const options: LDOptions = {
      streaming: true,
      logger: {
        error: console.error,
        warn: () => {},
        info: () => {},
        debug: () => {}
      }
    }

    this.client = initialize(CLIENT_ID, context, options)

    // Update the store with the initial flag values
    this.client.on('ready', () => {
      const flags = this.client.allFlags() as LaunchDarklyFeatureFlags
      this.featureFlagStore.updateFlags(flags)
    })

    // Update the store with flag changes (streaming)
    this.client.on('change', (flagUpdates: FlagUpdates) => {
      const flags = Object.keys(flagUpdates).reduce((flags, key) => {
        flags[key] = flagUpdates[key].current
        return flags
      }, {} as LaunchDarklyFeatureFlags)

      this.featureFlagStore.updateFlags(flags)
    })
  }
}
