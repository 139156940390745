import { Component, Output, EventEmitter, ViewChild, AfterViewInit, Input } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'
import { WithGlobals } from '../../../classes/with-globals'
import { DynamicRefService } from '../../../services/dynamic-ref-service/dynamic-ref.service'
import { TicketCreationModalComponent } from '../../../../framework/windows/ticket-creation-modal/ticket-creation-modal.component'
import { NgIf } from '@angular/common'
import { ButtonComponent } from '../../buttons/button/button.component'
import { ModalComponent } from '../../layout/modal/modal.component'
import { Size } from '@awork/_shared/types/size'
import { Color } from '@awork/_shared/types/color'

@Component({
  selector: 'aw-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  standalone: true,
  imports: [ModalComponent, NgIf, ButtonComponent, NgOptimizedImage]
})
export class ErrorModalComponent extends WithGlobals implements AfterViewInit {
  @Input() error: Error

  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()
  @Output() showing: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('modal') modal: ModalComponent

  shakeDuration: number
  protected translations = {
    ...q.translations.ErrorModalComponent,
    newTicket: q.translations.MainMenuComponent.newTicket,
    close: q.translations.common.close
  }
  protected size = Size
  protected color = Color

  constructor(private dynRefSvc: DynamicRefService) {
    super()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.modal && this.modal.show) {
        this.show()
      }
    }, this.shakeDuration)
  }

  show() {
    this.modal.show()
    this.showing.emit()
  }

  hide() {
    this.modal.hide()
    this.hiding.emit()
  }

  reload() {
    window.location.reload()
  }

  /**
   * Opens the create ticket modal with the actual error
   */
  createTicket(): void {
    this.hide()

    const [_, modal] = this.dynRefSvc.create(TicketCreationModalComponent)
    modal.error = this.error
  }
}
