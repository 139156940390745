import { Injectable } from '@angular/core'
import { DefaultUrlSerializer, UrlTree } from '@angular/router'

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return super.parse(this.lowerCaseUrl(url))
  }

  /**
   * Lowercase the url, but preserve the query params and fragment
   * @param {string} url
   */
  private lowerCaseUrl(url: string): string {
    if (url.includes('?')) {
      const parts = url.split('?')
      return `${parts[0].toLowerCase()}?${parts[1]}`
    }

    if (url.includes('#')) {
      const parts = url.split('#')
      return `${parts[0].toLowerCase()}#${parts[1]}`
    }

    return url.toLowerCase()
  }
}
