import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { Subscription } from 'rxjs'
import { FileModalService } from '../../../services/file-modal-service/file-modal.service'
import { WithGlobals } from '../../../classes/with-globals'
import { NgClass, NgIf } from '@angular/common'
import { FabButtonComponent } from '../../icon-buttons/fab-button/fab-button.component'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { DotsLoaderComponent } from '../../ui-help/dots-loader/dots-loader.component'
import { Size } from '@awork/_shared/types/size'
import { FileSizePipe } from '../../../pipes/file-size/file-size.pipe'
import { FileIconComponent } from '../file-icon/file-icon.component'

@AutoUnsubscribe()
@Component({
  selector: 'aw-inline-file-preview',
  templateUrl: './inline-file-preview.component.html',
  styleUrls: ['./inline-file-preview.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, FileIconComponent, FabButtonComponent, TooltipDirective, DotsLoaderComponent, FileSizePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineFilePreviewComponent extends WithGlobals implements OnInit, OnChanges, OnDestroy {
  @Input() file: FileUpload

  @Output() previewOpen: EventEmitter<void> = new EventEmitter<void>()
  @Output() previewClose: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('downloadLink') downloadLinkElement: ElementRef

  sizes = Size
  isLoading = false
  translations = q.translations.FilePreviewComponent

  view: 'image' | 'icon' | 'externalFile' = 'image'

  previewUrl: string

  protected userSubscription: Subscription

  constructor(
    public element: ElementRef,
    private fileModalService: FileModalService
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.file) {
      this.previewUrl = this.file.mimeType?.includes('image') ? this.file.reducedImage : this.file.filePreview

      this.initView()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file?.currentValue) {
      this.parseFileIfString()
    }
  }

  ngOnDestroy(): void {}

  /**
   * In case the component is being used as an element, the input is passed
   * as a JSON.stringify string
   */
  private parseFileIfString(): void {
    if (typeof this.file === 'string') {
      try {
        this.file = new FileUpload(JSON.parse(this.file))
      } catch (e) {
        // TODO: add logdna
      }
    }
  }

  /**
   * Inits the view according to the file type
   */
  initView(): void {
    if (this.file.externalFileUrl) {
      this.view = 'externalFile'
      return
    }

    const isImage = this.previewUrl && this.file.mimeType && FileUpload.isImageMimeType(this.file.mimeType)

    if (isImage) {
      this.isLoading = true
      this.view = 'image'
    } else {
      this.view = 'icon'
    }
  }

  /**
   * Show Image after loading successfully and hide file Icon
   */
  imageLoaded(): void {
    this.isLoading = false
  }

  /**
   * Handle broken images and show file Icon
   */
  imageLoadingError(): void {
    this.isLoading = false
    this.view = 'icon'
  }

  /**
   * Opens the carousel with the current selected file
   */
  onView(): void {
    this.previewOpen.emit()
    const previewHiding$ = this.fileModalService.showFilePreviewLightboxOverlay(this.file, null, null, false)
    previewHiding$.subscribe(() => this.previewClose.emit())
  }
}
